<template>
  <v-container fill-height justify-content-start :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
    <reader-overlay v-if="state.reader.open === 'READ_TAG_ID'" hideButton action="READ_TAG_ID">
      <template v-slot:content>
        <v-container justify-center fill-height>
        <v-card class="pa-3 text-center">
          <v-icon style="font-size: 90px;" v-if="state.reader.error" color="error">mdi-alert-circle-outline</v-icon>
          <v-icon style="font-size: 90px;" v-else>mdi-nfc-variant</v-icon>
          <div v-if="!state.reader.error" class="headline grey--text">{{$t('reader.read_identifier')}}</div>
          <i v-if="state.reader.open && !state.reader.connected && state.reader.showConnecting" class="body-2 grey--text">{{$t('reader.attempting_connection')}}</i>
          <div v-if="state.reader.error" class="flex-column mt-2" style="height: 100%;">
            <div class="title grey--text">{{$t(readerErrorText.title)}}</div>
            <div class="subheading grey--text" v-if="$te(readerErrorText.description)">{{$t(readerErrorText.description)}}</div>
          </div>
        </v-card>
        </v-container>
      </template>
    </reader-overlay>
    <v-layout column>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm6>
          <v-layout column class="pa-4">
            <!-- Facility/station -->
            <h2 class="display-2 raleway grey--text mt-3 mb-4">{{$t('sections.settings')}}</h2>
            <v-layout row wrap class="ma-0">
              <v-flex xs12 class="pa-1">
                <v-autocomplete
                  color="secondary"
                  :filter="autocompleteFilter"
                  :no-data-text="$t('pagination.no_data')"
                  item-text="name"
                  item-value="id"
                  :label="$t('fields.station')"
                  v-model="stationId"
                  :items="stations.list"
                  hide-details
                  outlined
                />
              </v-flex>
              <v-alert v-if="disconnectedPeripheralService" label type="error" outlined class="ma-1">{{ $t('peripherals.warnings.disconnected_peripheral_service') }}</v-alert>
              <v-flex xs12 class="pa-1 d-flex align-center">
                <v-autocomplete
                  color="secondary"
                  :filter="peripheralFilter"
                  :no-data-text="$t('pagination.no_data')"
                  clearable
                  :items="readers"
                  item-text="label"
                  item-value="id"
                  hide-details
                  outlined
                  :label="$t('fields.reader_peripheral')"
                  v-model="selectedReader"
                  :disabled="disconnectedPeripheralService"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label || item.hostname || item.id }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.label || item.hostname|| item.id }}
                  </template>
                </v-autocomplete>
                <tooltip-button
                  @click="$store.commit('state/setReaderState', 'READ_TAG_ID')"
                  :disabledTooltip="!(disconnectedPeripheralService || !peripherals.selectedReader)"
                  :disabled="disconnectedPeripheralService || !peripherals.selectedReader"
                  :tooltipText="disconnectedPeripheralService
                    ? $t('peripherals.warnings.disconnected_peripheral_service')
                    : $t('peripherals.warnings.reader_not_configured')"
                  buttonClass="ml-2"
                  color="primary"
                  text=test
                  outlined
                />
              </v-flex>
              <v-flex xs12 class="pa-1 d-flex align-center">
                <v-autocomplete
                  color="secondary"
                  :filter="peripheralFilter"
                  :no-data-text="$t('pagination.no_data')"
                  clearable
                  :items="printers"
                  item-text="label"
                  item-value="id"
                  hide-details
                  outlined
                  :label="$t('fields.printer_peripheral')"
                  v-model="selectedPrinter"
                  :disabled="disconnectedPeripheralService"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label || item.model || item.id }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.label || item.model || item.id }}
                  </template>
                </v-autocomplete>
                <tooltip-button
                  @click="sendPrinterAction(printTestBasePdf, 'application/pdf', () => {}, () => {})"
                  :disabledTooltip="!(disconnectedPeripheralService || !peripherals.selectedPrinter)"
                  :disabled="disconnectedPeripheralService || !peripherals.selectedPrinter"
                  :tooltipText="disconnectedPeripheralService
                    ? $t('peripherals.warnings.disconnected_peripheral_service')
                    : $t('peripherals.warnings.printer_not_configured')"
                  buttonClass="ml-2"
                  color="primary"
                  text=test
                  outlined
                />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { printTestBasePdf } from '@/config'
import searchMixins from '@/mixins/Search'
import permissionMixins from '@/mixins/Permission'
import peripheralMixins from '@/mixins/PeripheralSocket'
import printerMixins from '@/mixins/PrinterPeripheralActions'
export default {
  mixins: [
    searchMixins,
    peripheralMixins,
    permissionMixins,
    printerMixins
  ],
  components: {
    ReaderOverlay: () => import('@/components/view-components/ReaderOverlay.vue'),
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue')
  },
  data () {
    return {
      savingSettings: false,
      printTestBasePdf
    }
  },
  computed: {
    ...mapGetters('settings', ['isUnsaved']),
    ...mapState(['peripherals', 'state', 'stations', 'settings']),
    ...mapState({
      disconnectedPeripheralService: state => state.peripherals.disconnectedPeripheralService
    }),
    readers () {
      return this.peripherals.list.filter((p) => p.type === 'NFC_READER')
    },
    printers () {
      return this.peripherals.list.filter((p) => p.type === 'PRINTER')
    },
    readerErrorText () {
      if (!this.state.reader.error) {
        return {}
      } else if (this.$te(`reader.error.${this.state.reader.error}.title`)) {
        return {
          title: `reader.error.${this.state.reader.error}.title`,
          description: `reader.error.${this.state.reader.error}.description`
        }
      } else {
        return {
          title: `reader.error.FALLBACK.title`,
          description: `reader.error.FALLBACK.description`
        }
      }
    },
    stationId: {
      get () {
        return localStorage.getItem('selectedStationId')
      },
      set (val) {
        this.$store.commit('settings/setSelectedStationId', val)
      }
    },
    selectedReader: {
      get () {
        return localStorage.getItem('selectedReader')
      },
      set (val) {
        this.$store.commit('peripherals/setReader', val)
        if (val) {
          this.connect().catch(() => {
            this.$store.commit('state/setMessage', { text: this.$t('reader.error.socket_connection'), color: 'error' })
          })
        } else {
          this.disconnect()
        }
      }
    },
    selectedPrinter: {
      get () {
        return this.$store.state.peripherals.selectedPrinter
      },
      set (val) {
        this.$store.commit('peripherals/setPrinter', val)
        if (val) {
          this.connect().catch(() => {
            this.$store.commit('state/setMessage', { text: this.$t('reader.error.socket_connection'), color: 'error' })
          })
        } else {
          this.disconnect()
        }
      }
    }
  },
  methods: {
    saveClientSettings () {
      this.savingSettings = true
      this.$store.dispatch('settings/save').then(() => {
        this.savingSettings = false
      })
    },
    peripheralFilter (item, queryText, itemText) {
      var text = `${item.label} ${item.hostname}`
      queryText = this.sanitize(queryText)
      return this.sanitize(text).indexOf(queryText) !== -1
    }
  },
  mounted () {
    let promises = []
    promises.push(this.$store.dispatch('settings/get').catch(() => {}))
    promises.push(this.$store.dispatch('peripherals/list', { save: true }).catch(() => {}))
    promises.push(this.$store.dispatch('stations/list', { save: true }).catch(() => {}))
  }
}
</script>
